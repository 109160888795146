import {BrowserRouter, Routes, Route} from "react-router-dom"
import Careers from "./pages/careers/Careers";
import Home from "./pages/home/Home";
import Services from "./pages/services/Services";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog/blogDetails"
import CaseStudy from "./pages/casestudy/caseStudy";
import CaseStudyDetail from "./pages/casestudy/caseStudyDetails";
import Platform from "./pages/platform/Platform";
import Contact from "./pages/contact/Contact";
// import Ecommerce from "./pages/ecommerce/Ecommerce";
// import Video from "./pages/video/Vidoe";
// import Software from "./pages/software/software";
// import Business from "./pages/business/business";
// import Wellness from "./pages/wellness/wellness";
// import Speech from "./pages/speech/Speech"
import NotFound from "./pages/notFound/notFound";
import Navbar from "./components/navbar/Navbar";
import ScrollToTop from "./ScrollToTop"
import { useState, useEffect } from "react";
import Footer from "./components/Footer";

function App() {
  const [theme, setTheme] = useState("dark")

  useEffect(()=> {
    document.body.className = theme;
  },[theme] );
  return (
    <div className={`app-container ${theme}`}>
    <BrowserRouter>
    <ScrollToTop />
    <Navbar theme={theme} setTheme={setTheme}/>
    <Routes>
      <Route path="/" element={ <Home/>}></Route>
      <Route path="/Platform" element={ <Platform/>}></Route>
      <Route path="/Services" element={<Services/>}></Route>
      <Route path="/CaseStudy" element={<CaseStudy/>}></Route>
      <Route path="/case-study/:slug" element={<CaseStudyDetail />}/>
      <Route path="/Contact" element={<Contact/>}></Route>
      <Route path="/Careers" element={ <Careers/>}></Route>
      <Route path="/Blog" element={<Blog/>}></Route>
      <Route path="/blog/:slug" element={<BlogDetails />} />
      {/* <Route path="/Ecommerce" element={<Ecommerce/>}></Route>
      <Route path="/Video" element={<Video/>}></Route>
      <Route path="/Speech" element={<Speech/>}></Route>
      <Route path="/Software" element={<Software/>}></Route>
      <Route path="/business" element={<Business/>}></Route>
      <Route path="/wellness" element={<Wellness/>}></Route> */}
      <Route path="*" element={<NotFound/>}></Route>
    </Routes> 
    <Footer />   
    </BrowserRouter>
    </div>
  );
}

export default App;
