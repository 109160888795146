import { FaRobot, FaChalkboardTeacher, FaUsers, FaCogs } from "react-icons/fa"
import { IoCallOutline, IoLocateOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import AnnotationOne from "../src/images/anno1.jpg"
import AnnotationTwo from "../src/images/anno2.png"
import EcommerceImg from "../src/images/ecommerce.jpg"
import Videoedit from "../src/images/improve-video-data-collection.jpg"
import Business from "../src/images/speach-recogn-business.jpg"
import Wellness from "../src/images/wellness-2758146_1920-min.jpg"
import img1 from "../src/images/IBMlogo-min.jpg"
import img2 from "../src/images/amplitudeLogo-min.jpg"
import img3 from "../src/images/armLogo-min.jpg"
import img4 from "../src/images/canvaLogo-min.jpg"
import img5 from "../src/images/dellLogo-min.jpg"
import img6 from "../src/images/greenLogo-min.jpg"
import img7 from "../src/images/hubSpotLogo-min.jpg"
import img8 from "../src/images/larkLogo-min.jpg"
import img9 from "../src/images/newrelicLogo-min.jpg"
import img10 from "../src/images/nestuLogo-min.jpg"
import img12 from "../src/images/oracleLogo-min.jpg"
import img13 from "../src/images/stripeLogo-min.jpg"
import img14 from "../src/images/vantaLogo-min.jpg"
import img15 from "../src/images/wLogo-min.jpg"
import img16 from "../src/images/zendeskLogo-min.jpg"
import img17 from "../src/images/mongoDBLogo-min.jpg"
import img18 from "../src/images/letterNLogo-min.jpg"



export const services = [
    {
        id: 1,
        icon: <FaRobot />,
        title: "Data Annotation via Agicrowds",
        info: "Achieve accuracy and precision with our high-quality data annotation services, designed to enhance your AI models."
    },
    {
        id: 2,
        icon: <FaChalkboardTeacher />,
        title: "AI/ML Consulting",
        info:  "Benefit from expert advice to refine your AI/ML strategy and achieve impactful, measurable results."
    },
    {
        id: 2,
        icon: <FaUsers />,
        title: "Crowdsourced Data Collection via AgiCrowds",
        info:  "Scale your data training with our AgiCrowds mobile app and platform, enabling seamless multimodal data collection and training."
    },
    {
        id: 3,
        icon: <FaCogs />,
        title: "Custom AI/ML Solutions",
        info:  "Receive bespoke AI/ML solutions tailored to meet your specific needs for maximum efficiency and adaptability."
    },
]



  export const contacts = [
    {
        name: "Email",
        value: "info@agiphi.com",
        icon: <MdOutlineAlternateEmail />
    },
    {
        name: "Phone Number",
        value: "307 222 9101",
        icon: <IoCallOutline />
    },
    {
        name: "Address",
        value: "30 N Gould St Ste N Sheridan, WY 82801 USA",
        icon: <IoLocateOutline />
    }

  ]


  export const caseStudyData = [{
    id: 1,
    src: AnnotationOne, 
    alt: "AnnotationOne", 
    className: "annot__top",
    title: "Software Solutions",
    info:  "Discover our cutting-edge software solutions designed to enhance your data annotation and machine learning projects. Our software tools streamline your workflow and improve accuracy.",
    link: "/Software",
    buttonText: "Learn More",
  },
  {
    id: 2,
    src: AnnotationTwo,
    alt: "AnnotationTwo",
    className: "annot__top", 
    title: "Speech recognition services",
    info:  "Explore our advanced speech recognition services tailored to elevate your voice-to-text conversion and transcription projects. Our cutting-edge technology ensures seamless integration and delivers unparalleled accuracy.",
    link: "/Speech",
    buttonText: "Learn More",
  },
  {
    id: 3,
    src: EcommerceImg,
    alt: "Ecommerce image", 
    className: "annot__top",
    title: "Improving search relevance",
    info:  "Online shoppers seek a convenient and user-friendly experience to easily find products. To keep search results relevant, eCommerce companies must continually compare customer queries with generated results to eliminate any irrelevance.",
    link: "/Ecommerce",
    buttonText: "Learn More",
  },
  {
    id: 4,
    src: Videoedit,
    alt: "Videos_quality",
    className: "annot__top",
    title: "High-quality video data collection",
    info:  "We are committed to enhancing captioning capabilities in our products, including video and video conferencing solutions, to effectively support multiple languages.",
    link: "/Video",
    buttonText: "Learn More",
  },
  {
    id: 5,
    src: Business,
    alt: "business",
    className: "annot__top",
    title: "Top global tech company builds long-standing partnership with AGIPHI",
    info:  "A leading technology company seeks to expand its portfolio of online speech and text recognition services on a global scale. The organization has already established a prominent position in English-speaking markets by enabling users to transcribe recorded or live speech into text and to convert text into speech.",
    link: "/Business",
    buttonText: "Learn More",
  },
  {
    id: 6,
    src: Wellness,
    alt: "sound",
    className: "annot__top",
    title: "Improving language support for a global technology leader by gathering data.",
    info:  "A leading global technology company aimed to expand support for its keyboard in multiple countries to encourage customer adoption. To accomplish this, the keyboard needed to accommodate a wide range of languages and dialects, including rare languages spoken by only a few thousand people.",
    link: "/Wellness",
    buttonText: "Learn More",
  }
  ]

//   export const testimonials = [
//     {
//     id: 1,
//     name: "Diana Ayi",
//     story: " After struggling with data inaccuracies for years, Diana Ayi partnered with us and saw a dramatic improvement. By using our annotation services, they improved data accuracy by 75%, which significantly boosted their operational efficiency and decision-making capabilities.",
//     avatar: require("./images/hair-Diana Ayi.jpg")
//     },
//     {
//         id: 2,
//         name: "Daniela Vinyo",
//         story: "Faced with lengthy processing times, Daniela Vinyo turned to our automation solutions. We helped them reduce processing time by 50%, allowing them to reallocate resources and focus on growth strategies, resulting in improved overall performance.",
//         avatar: require("./images/Daniela.jpg")
//         },  
//         {
//             id: 3,
//             name: "Edem Quist",
//             story: "Edem Quist needed to engage their users more effectively. Our personalized marketing strategies increased user engagement by 40%.This boost in engagement helped them foster stronger customer relationships and enhance their brand presence.",
//             avatar: require("./images/man-man-Edem.jpg")
//             },  
//             {
//                 id: 4,
//                 name: "Grace Page",
//                 story: "Looking to maximize their return on investment, Grace Page utilized our comprehensive data analysis services.They saw a 60% boost in ROI, enabling them to make more informed business decisions and achieve their financial goals more quickly.",
//                 avatar: require("./images/glasses-Grace Page.jpg")
//                 },    
//                 {
//                     id: 5,
//                     name: "Ben Lavoe",
//                     story: "Ben Lavoe wanted to enhance their customer service through automated solutions. By integrating our chatbot services,they increased sales by 30% and achieved faster response times, leading to higher customer satisfaction and loyalty.",
//                     avatar: require("./images/man-man-Ben Lavoe.jpg")
//                     },    
  
//   ]





 export const partnersLogo = [
  { src: img1, alt: "IBM Logo" },
  { src: img2, alt: "Amplitude Logo" },
  { src: img3, alt: "ARM Logo" },
  { src: img4, alt: "Canva Logo" },
  { src: img5, alt: "Dell Logo" },
  { src: img6, alt: "Green Logo" },
  { src: img7, alt: "HubSpot Logo" },
  { src: img8, alt: "Lark Logo" },
  { src: img9, alt: "newrelicLogo" },
  { src: img10, alt: "Nestul Logo" },
  { src: img12, alt: "oracle Logo" },
  { src: img13, alt: "stripe Logo" },
  { src: img14, alt: "vanta Logo" },
  { src: img15, alt: "wLogo Logo" },
  { src: img16, alt: "zendesk Logo" },
  { src: img17, alt: "mongoDB Logo" },
  { src: img18, alt: "letterN Logo" },

]
    
