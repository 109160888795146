import Logo from "./../images/Agiphi (3).PNG"
import {Link} from "react-router-dom"
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { AiOutlineX } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
    <div className="footer__container">
        <article>
            <Link to="/" className="footer-logo">
            <img src={Logo} alt="Footer logo" />
            </Link>
            <p>Agiphi provides advanced tools and tailored solutions to optimize your data and machine learning processes, driving impactful and measurable results.</p>           
        <div className="footer-socials">
            <a href="https://linkedin.com/" target="_blank" rel="noreferrer noopener"> <FaLinkedin/></a>
            <a href="https://facebook.com/" target="_blank" rel="noreferrer noopener"> <FaFacebookF/></a>
            <a href="https://x.com/" target="_blank" rel="noreferrer noopener"> <AiOutlineX/></a>
        </div>
        </article>
        <article>
            <h4>Permalinks</h4>
            <Link to="/Clients">What Others Think</Link>
            <Link to="/Platform">Request Demo</Link>
            <Link to="/Services">Services</Link>
            <Link to="/Contact">Contact</Link>
        </article>
        <article>
            <h4>Permalinks</h4>
            <Link to="/Services">Tools and Equipment</Link>
            <Link to="/Blog">Blog</Link>
            <Link to="/Clients">Our Clients</Link>
            <Link to="/Contact">Contact Us</Link>
        </article>
        <article>
            <h4>Ecosystem</h4>
            <Link to="/Blog">Latest Update</Link>
            <Link to="/Careers">Careers</Link>
            <Link to="/Platform">Our Platform</Link>
            <Link to="/Contact">Subscribe</Link>
        </article>
    </div>
    <div className="footer__copyright">
        <p>&copy; 2025 Agiphi . All rights reserved.</p>
    </div>
    </footer>
  )
}

export default Footer
