import Header from '../../components/Header';
import HeaderImage from '../../images/header-bg.jpg'
import Screenshot1 from '../../images/Agicrowds1.png'; // Importing the first screenshot
import Screenshot2 from '../../images/Agicrowds2.png'; // Importing the second screenshot
import {Link} from 'react-router-dom'
import './platform.css'

const Platform = () => {
  return (
   <>
   <Header title="Agicrowds Data Platform" image={HeaderImage}>
   </Header>
    <div className="container platform">
          <section className="platform-development">
            <div className='text'>
            <h2>Innovative Multimodal Data Collection and Annotation Platform</h2>
        <p>
          Manage and track data collection and annotation processes in real time with Agicrowds. Our platform supports seamless data collectiion and annotations across text, audio, and video, allowing for efficient and accurate data handling.
        </p>
            </div>
        <ul className="key-features">
          <li>
            <strong>Comprehensive Integration:</strong> Connect to both local and cloud storage solutions for secure and flexible data management.
          </li>
          <li>
            <strong>Cross-Device Compatibility:</strong> Access Agicrowds from mobile, tablet, or desktop devices, ensuring flexibility for on-the-go project management.
          </li>
        </ul>
      </section>

      <section className="visuals-demo">
        <h2>Screenshots</h2>
        <div className="screenshots">
            <img src={Screenshot1} alt="Screenshot of Agicrowds Annotation Platform" />
            <img src={Screenshot2} alt="Another screenshot of Agicrowds Annotation Platform" />
          {/* Add more screenshots as needed */}
        </div>
        <a href="https://acrowds.com" className="btn" target="_blank" rel="noopener noreferrer">
    Sign Up
  </a>
      </section>
    </div>
    </>
  );
};

export default Platform;

