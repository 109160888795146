import {motion} from "framer-motion"
import dataAnnot from "../images/data annotation.jpg"
import SectionHead from "./SectionHead"
const CompanyOverview = () => {
  return (
<section className="company-overview">
    <div className="container company__container">
        { <SectionHead title="Company Overview"/>}

        <div className="company-overview-img">     
      
         <div className="company-overview-img">
            <motion.img 
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1}}
            transition={{
              delay:0.2,
              x: {type: "spring", stiffness: 60},
              opacity: {duration: 0.4},
              ease: "easeIn",
              duration: 1,
            }}
            src={dataAnnot} alt="" />
          </div>
            <p>At Agiphi, we combine quality and innovation to deliver customized solutions that align with your unique objectives.</p>
       
    </div>
    </div>
</section>
  )
}

export default CompanyOverview
