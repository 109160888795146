// import {Link} from "react-router-dom"
// import {caseStudyData} from "../../data"
// import Header from "../../components/Header"
// import "../../components/mainHeader.css"
// import '../casestudy/caseStudy.css'
// import HeaderImgae from "../../images/header-bg.jpg"

// const caseStudy = () => {
//   return (
//   <>
//   <Header title="Case studies" image={HeaderImgae}>
//   Transforming AI initiatives worldwide with superior high-quality data.
//   </Header>
//    <section className="about__story">
//     <div className="container about__story-container">
// <div className="gird__container">
 
//     {caseStudyData.map((item)=>(
//     <div key={item.id}>
//       <div className="image-story">
//             <img
//               src={item.src}
//               alt={item.alt}
//             />
//       </div>
//       <h2>{item.title}</h2>
//           <p>{item.info}</p>
//           <Link to={item.link} className="btn case-btn">
//             {item.buttonText}
//           </Link>
//     </div>
//     ))}
// </div>

// </div>    

//    </section>
//   </>
// )
// }

// export default caseStudy
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import "../../components/mainHeader.css";
import "../casestudy/caseStudy.css";
import HeaderImage from "../../images/header-bg.jpg";

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const response = await fetch("http://localhost:1337/api/case-studies?populate=*");
        const data = await response.json();
        setCaseStudies(data.data); // Set the case studies
        setLoading(false);
      } catch (error) {
        console.error("Error fetching case studies:", error);
        setLoading(false);
      }
    };
    fetchCaseStudies();
  }, []);

  if (loading) {
    return <p className="loading-text">Loading...</p>;
  }

  if (!caseStudies.length) {
    return <p>No case studies available.</p>;
  }

  return (
    <>
      <Header title="Case Studies" image={HeaderImage}>
        Transforming AI initiatives worldwide with superior high-quality data.
      </Header>
      <section className="about__story">
        <div className="container about__story-container">
          <div className="grid__container">
            {caseStudies.map((caseStudy) => {
              const { id, Title, Slug, Image, description } = caseStudy;
              const imageUrl = Image?.[0]?.formats?.thumbnail?.url
                ? `http://localhost:1337${Image[0].formats.thumbnail.url}`
                : "path/to/default-image.jpg";
              const caseDescription = description?.[0]?.children?.[0]?.text || "No description available.";

              return (
                <div key={id} className="case-study-card">
                  <div className="image-story">
                    <img src={imageUrl} alt={Title || "Untitled Case Study"} />
                  </div>
                  <h2>{Title || "Untitled Case Study"}</h2>
                  <p>{caseDescription}</p>
                  <Link to={`/case-study/${Slug}`} className="btn case-btn">
                    Read More
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default CaseStudies;
