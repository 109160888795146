import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../components/mainHeader.css";
import "../casestudy/caseStudy.css";

const CaseStudyDetail = () => {
  const { slug } = useParams();
  const [caseStudy, setCaseStudy] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCaseStudy = async () => {
      try {
        const response = await fetch(
          `http://localhost:1337/api/case-studies?filters[Slug][$eq]=${slug}&populate=Sections`
        );
        const data = await response.json();

        console.log("Fetched data:", data); // Logs the entire API response

        if (data.data && data.data.length > 0) {
          const fetchedCaseStudy = data.data[0];
          console.log("Case study data:", fetchedCaseStudy); // Logs the specific case study object

          // Ensure Sections is always an array
          setCaseStudy({
            ...fetchedCaseStudy,
            Sections: fetchedCaseStudy.Sections || [],
          });
        } else {
          console.warn("No case study found for this slug.");
        }
      } catch (error) {
        console.error("Error fetching case study details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseStudy();
  }, [slug]);

  if (loading) {
    return <p className="loading-text">Loading...</p>;
  }

  if (!caseStudy) {
    return <p>Case study not found.</p>;
  }

  const { Title, Sections = [] } = caseStudy; // Provide default empty array for Sections

  return (
    <div className="case-study-detail">
      <h1>{Title || "Untitled Case Study"}</h1>
      {Sections.length > 0 ? (
        Sections.map((section) => (
          <section key={section.id} className="case-study-section">
            <h2>{section.Heading || "No Heading"}</h2>
            <hr />
            {section.Content && section.Content.length > 0 ? (
              section.Content.map((content, index) => (
                <p key={index}>
                  {content.children && content.children.length > 0
                    ? content.children[0].text || "No content available."
                    : "No content available."}
                </p>
              ))
            ) : (
              <p>No content available for this section.</p>
            )}
          </section>
        ))
      ) : (
        <p>No sections available.</p>
      )}
    </div>
  );
};

export default CaseStudyDetail;
