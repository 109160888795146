import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import "../blog/blog.css";

const BlogDetails = () => {
  const { slug } = useParams(); 
  const navigate = useNavigate();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios
      .get(`http://localhost:1337/api/blogs?filters[slug][$eq]=${slug}&populate=*`)
      .then((response) => setPost(response.data.data[0])) // Fetch the blog post with the matching slug
      .catch((error) => console.error("Error fetching blog post:", error));
  }, [slug]);

  if (!post) return <p className="loading-text">Loading...</p>;

  const { blogTitle, blogContent, coverImage } = post;

  return (
    <div className="blog-details">
      
      {coverImage?.url && (
        <img
          src={`http://localhost:1337${coverImage.url}`}
          alt={blogTitle}
          className="blog-details__image"
        />
      )}
      <h1>{blogTitle}</h1>
      <div>
  {Array.isArray(blogContent)
    ? blogContent.map((content, index) => (
        <p key={index}>
          {content.children && content.children.map((child) => child.text).join(' ')}
        </p>
      ))
    : <p>{blogContent}</p>}
</div>
{/* Back Arrow Icon */}
<div className="back-arrow" onClick={() => navigate(-1)}>
        <span>← Back to Blog</span>
      </div>
    </div>
  );
};

export default BlogDetails;
